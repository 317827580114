import * as React from 'react';
import { useMemo, useState } from 'react';
import {
    AvatarTemplate,
    Navbar,
    SourcesSidePanel,
    TextTemplate,
} from '@recourseai/components';
import { useQuery } from '@tanstack/react-query';
import { moocAPI } from '../services';
import useAsyncError from '../hooks/useAsyncError';
import { useHistory, useParams } from 'react-router-dom';
import ExerciseAPI from '../components/activities/ExerciseAPI';
import { Session } from '../components/activities/ActivityContent';
import LoadingSpinner from '../components/generic/LoadingSpinner';
import { InteractionsContextProvider } from '@recourseai/components/src/utils/interaction/InteractionContext';
import {
    Center,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Image,
    Text,
} from '@chakra-ui/react';
import { useInteractionSetting } from '@recourseai/components/src/stores';
import { ModalWithNoInitialFocus } from '@recourseai/components/src/atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';
import ROUTES from '../consts/routes';
import { scrollBarStyles } from '@recourseai/components/src/theme/consts';
import config from '../consts/config';
import FPSDisplay from '@recourseai/components/src/atoms/FPSDisplay';

const createOrGetActiveAttempt = (
    activityId: string,
    courseId: string,
    exerciseSessionId?: string,
): Promise<any> => {
    return moocAPI.post(`course/${courseId}/activity/${activityId}/attempt/`, {
        exercise_session_id: exerciseSessionId,
    });
};

const Consultation = () => {
    const {
        isTextMode,
        isModalOpen,
        closeModal,
        attachmentToDisplay,
        isSourcesDrawerOpen,
        openDrawer,
        closeDrawer,
    } = useInteractionSetting([
        'isTextMode',
        'isModalOpen',
        'closeModal',
        'attachmentToDisplay',
        'isSourcesDrawerOpen',
        'openDrawer',
        'closeDrawer',
    ]);

    const throwAsyncError = useAsyncError();

    const { courseId, activityId } = useParams<{
        courseId: string;
        activityId: string;
    }>();

    const { data: activity } = useQuery<Activity>(
        ['course', courseId, 'activity', activityId],
        () => moocAPI.get(`course/${courseId}/activities/${activityId}/`),
        {
            onError: throwAsyncError,
        },
    );

    const exerciseAPI = useMemo(
        () => (activity ? new ExerciseAPI(activity) : undefined),
        [activity],
    );

    const { data: session } = useQuery<Session>(
        ['interaction', activity?.config.exercise_api_route, 'session'],
        () => {
            const attempts = activity?.student_activity?.attempts || [];
            const existingSessionId = attempts.find(
                attempt => attempt.completed_datetime === null,
            )?.exercise_session_id;

            if (existingSessionId) {
                return exerciseAPI!.sessionDetails(existingSessionId);
            } else {
                return exerciseAPI!
                    .startSession()
                    .then(({ id }) =>
                        Promise.all([
                            exerciseAPI!.sessionDetails(id),
                            createOrGetActiveAttempt(activityId, courseId, id),
                        ]),
                    )
                    .then(([sessionDetails]) => sessionDetails);
            }
        },
        {
            enabled: !!exerciseAPI,
            cacheTime: 0,
            onError: throwAsyncError,
        },
    );

    const [currentSource, setCurrentSource] = useState<Citation>();

    const onCitationClick = (citation: Citation) => {
        openDrawer();
        setCurrentSource(citation);
    };

    const history = useHistory();

    if (!activity || !session) {
        return (
            <Center h='100%'>
                <LoadingSpinner />
            </Center>
        );
    }

    return (
        <InteractionsContextProvider
            session={session}
            useStreaming={activity!.config.use_streaming}
            exerciseAPI={exerciseAPI!}
            // Can be null
            messageDisplayLimit={
                activity!.config.max_history_messages || undefined
            }
        >
            <ModalWithNoInitialFocus
                isOpen={isModalOpen}
                onClose={closeModal}
                scrollBehavior='inside'
                size='6xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{attachmentToDisplay?.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody sx={scrollBarStyles}>
                        <Image src={attachmentToDisplay?.url} />
                        {attachmentToDisplay?.description && (
                            <Text fontSize='xs'>
                                {attachmentToDisplay?.description}
                            </Text>
                        )}
                    </ModalBody>
                </ModalContent>
            </ModalWithNoInitialFocus>
            <Navbar
                title={activity.title}
                onClickClose={() => {
                    history.push(
                        `/${ROUTES.COURSES}/${courseId}/${ROUTES.ACTIVITY}/${activityId}`,
                    );
                }}
            />
            <SourcesSidePanel
                isSourcesDrawerOpen={isSourcesDrawerOpen}
                onClose={() => {
                    closeDrawer();
                }}
                currentSource={currentSource}
            />
            {!isTextMode && (
                <AvatarTemplate
                    avatar_config={
                        session.active_stage.interaction_stage
                            .avatar_config as RapportAvatarConfig
                    }
                    backgroundSrc={
                        session.active_stage.interaction_stage.background_id
                    }
                    title={activity.title}
                    onCitationClick={onCitationClick}
                />
            )}
            {isTextMode && (
                <TextTemplate
                    title={activity.title}
                    onCitationClick={onCitationClick}
                />
            )}
            {config.REACT_APP_ENV !== 'production' && (
                <FPSDisplay position='fixed' bottom={0} right={0} />
            )}
        </InteractionsContextProvider>
    );
};

export default Consultation;
