import React, { Fragment, useState } from 'react';
import {
    Button,
    HStack,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import { ModalWithNoInitialFocus } from '../../../overlays';
import { ModalProps } from '../../../modals/types';

export type RapportModalName =
    | 'RapportSessionCouldNotInitialise'
    | 'RapportSessionDisconnectedTtsFailed'
    | 'RapportSessionDisconnectedTimeout';

type ModalTemplate<T> = {
    code: RapportModalName;
    title: string;
    content: JSX.Element;
    actions?: (
        props: T,
    ) => {
        label: string;
        loadingText?: string;
        onClick: () => void;
    }[];
    modalProps?: any; // ThemingProps<'Modal'>;
};

const ModalFactory = <T,>(template: ModalTemplate<T>) => {
    const _Modal: React.FC<T & ModalProps> = props => {
        const [runningAction, setRunningAction] = useState<null | string>(null);
        const actions = template.actions?.(props);
        return (
            <ModalWithNoInitialFocus
                isOpen={props.show}
                closeOnOverlayClick={false}
                onClose={props.onClose}
                {...template.modalProps}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{template.title}</ModalHeader>
                    <ModalBody>{template.content}</ModalBody>
                    <ModalFooter>
                        <HStack>
                            {actions?.map(({ label, ...props }) => (
                                <Button
                                    key={label}
                                    colorScheme='brand.black'
                                    isLoading={runningAction === label}
                                    isDisabled={
                                        !!runningAction &&
                                        runningAction !== label
                                    }
                                    {...props}
                                    onClick={() => {
                                        setRunningAction(label);
                                        props?.onClick();
                                    }}
                                >
                                    {label}
                                </Button>
                            ))}
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </ModalWithNoInitialFocus>
        );
    };
    _Modal.displayName = template.code + 'Modal';
    return _Modal;
};

export const RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE: ModalTemplate<{
    finish: () => void;
    tryAgainAvatar: () => void;
}> = {
    code: 'RapportSessionDisconnectedTimeout',
    title: 'The session has disconnected',
    content: (
        <Fragment>
            <p>
                You have taken longer than the recommended time to complete this
                activity.
            </p>
            <p>
                Don’t worry, all your progress is saved. Simply rejoin and
                continue with your conversation, or you may finish and see your
                feedback.
            </p>
        </Fragment>
    ),
    actions: props => [
        {
            label: 'Finish',
            loadingText: 'Finishing',
            onClick: props.finish,
        },
        {
            label: 'Rejoin',
            loadingText: 'Rejoining',
            onClick: props.tryAgainAvatar,
        },
    ],
};

export const RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE: ModalTemplate<{
    useTextChat: () => void;
    tryAgainAvatar: () => void;
}> = {
    code: 'RapportSessionDisconnectedTtsFailed',
    title: 'Try optimizing your setup',
    content: (
        <Fragment>
            <p className='font-weight-bold'>
                Please ensure you close other applications and tabs/windows and
                plug in charger.
            </p>
            <p>
                You can continue in text-mode and always enable the avatar again
                later by clicking the avatar icon.
            </p>
            <p>
                If you continue to have issues, please reach out to our support
                team at{' '}
                <a href='mailto:support@recourseai.com'>
                    support@recourseai.com
                </a>{' '}
                who will be more than happy to help.
            </p>
        </Fragment>
    ),
    actions: props => [
        {
            label: 'Continue with text-chat',
            onClick: props.useTextChat,
        },
        {
            label: 'Resume with avatar',
            loadingText: 'Resuming',
            onClick: props.tryAgainAvatar,
        },
    ],
    modalProps: {
        size: 'lg',
    },
};

export const RAPPORT_SESSION_COULD_NOT_INITIALISE: ModalTemplate<{
    useTextChat: () => void;
    tryAgainAvatar: () => void;
}> = {
    code: 'RapportSessionCouldNotInitialise',
    title: "It seems we're having trouble loading your avatar at the moment.",
    content: (
        <Fragment>
            <dl>
                <dt>
                    <strong>Try Again Later</strong>
                </dt>
                <dd>
                    <Text paddingLeft='3'>
                        You can wait for a while and try loading your avatar
                        again later or continue the interaction using text chat
                        (without the avatar). The loading process may succeed on
                        a subsequent attempt.
                    </Text>
                </dd>
            </dl>
            <p>
                If you continue to have issues, please reach out to our support
                team at{' '}
                <a href='mailto:support@recourseai.com'>
                    support@recourseai.com
                </a>{' '}
                who will be more than happy to help.
            </p>
        </Fragment>
    ),
    actions: props => [
        {
            label: 'Continue with text-chat',
            onClick: props.useTextChat,
        },
        {
            label: 'Try again',
            loadingText: 'Loading',
            onClick: props.tryAgainAvatar,
        },
    ],
    modalProps: {
        size: '2xl',
    },
};
export const RapportSessionCouldNotInitialiseModal = ModalFactory(
    RAPPORT_SESSION_COULD_NOT_INITIALISE,
);

export const RapportSessionDisconnectedTtsFailedModal = ModalFactory(
    RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
);

export const RapportSessionDisconnectedTimeoutModal = ModalFactory(
    RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
);

export const getModalProps = (name: RapportModalName) =>
    [
        RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
        RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
        RAPPORT_SESSION_COULD_NOT_INITIALISE,
    ].find(t => t.code === name)?.modalProps;
