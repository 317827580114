import * as React from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { Icon } from '../../atoms';
import { IconProps } from '../../atoms/Icon/Icon';

interface Props {
    ariaLabel: string;
    icon: IconProps['iconChoice'];
    buttonBackgroundColor?: 'black' | 'white';
    tooltipLabel: string;
    onClick: () => void;
}

export default function ControlToggle({
    ariaLabel,
    icon,
    buttonBackgroundColor = 'white',
    tooltipLabel,
    onClick,
}: Props) {
    return (
        <Tooltip label={tooltipLabel} placement='top'>
            <IconButton
                aria-label={ariaLabel}
                backgroundColor={buttonBackgroundColor}
                boxSize='50px'
                borderRadius='full'
                onClick={onClick}
                _hover={{ backgroundColor: 'none' }}
            >
                <Icon
                    iconChoice={icon}
                    fontAwesomeProps={{
                        size: 'lg',
                        color:
                            buttonBackgroundColor === 'black'
                                ? 'white'
                                : 'black',
                    }}
                />
            </IconButton>
        </Tooltip>
    );
}
