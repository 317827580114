import React, { Fragment } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { Box, Center } from '@chakra-ui/react';
import { useInteractionSettingsStore } from '../../stores';

export interface Props {
    avatar_config: RapportAvatarConfig;
    rapportSceneId: string;
    backgroundSrc?: string;
}

const DEFAULT_BACKGROUND_COLOR = '#EEEEEE';
const RAPPORT_BASE_URL = 'https://api.rapport.cloud/api/v1/';

export default function AvatarWrapper({
    backgroundSrc,
    avatar_config,
    rapportSceneId,
}: Props) {
    const { scene } = avatar_config;

    const isSourcesDrawerOpen = useInteractionSettingsStore(
        state => state.isSourcesDrawerOpen,
    );

    return (
        <Fragment>
            {backgroundSrc && !backgroundSrc.startsWith('#') ? (
                <Box
                    w='100%'
                    h='100%'
                    backgroundImage={backgroundSrc}
                    backgroundSize='cover'
                    backgroundPosition='bottom'
                />
            ) : (
                <Box
                    w='100%'
                    h='100%'
                    backgroundColor={backgroundSrc || DEFAULT_BACKGROUND_COLOR}
                />
            )}
            {isDesktop && (
                <Box
                    h='100%'
                    w={{ base: '100%', xl: '60%' }}
                    position='fixed'
                    left={{
                        base: '-350px',
                        xl: isSourcesDrawerOpen ? '0px' : '100px',
                    }}
                    bottom='0px'
                >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <rapport-scene
                        id={rapportSceneId}
                        base-url={RAPPORT_BASE_URL}
                        project-id={scene.projectId}
                        project-token={scene.projectToken}
                        ai-user-id={scene.aiUserId}
                        lobby-zone-id={scene.lobbyZoneId}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Box>
            )}
            {isMobile && (
                <Center h='90%' w='100%' position='fixed' bottom='0'>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <rapport-scene
                        id={rapportSceneId}
                        base-url={RAPPORT_BASE_URL}
                        project-id={scene.projectId}
                        project-token={scene.projectToken}
                        ai-user-id={scene.aiUserId}
                        lobby-zone-id={scene.lobbyZoneId}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Center>
            )}
        </Fragment>
    );
}
