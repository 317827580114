import * as React from 'react';
import { createContext, useContext, useMemo } from 'react';
import useInteraction, { InitProps } from './useInteraction';

type InteractionContextType = ReturnType<typeof useInteraction>;
type InteractionContextDataType = Pick<
    InteractionContextType,
    'hints' | 'messages'
>;
type InteractionContextStatusType = Pick<
    InteractionContextType,
    'agentState' | 'isAgentBusy' | 'awaitingResponse' | 'isDisabled'
>;
type InteractionContextAPIType = Pick<
    InteractionContextType,
    | 'interrupt'
    | 'sendMessage'
    | 'addActionProcessor'
    | 'removeActionProcessor'
    | 'activeStage'
>;

export const InteractionContextData = createContext<InteractionContextDataType | null>(
    null,
);
export const InteractionContextAPI = createContext<InteractionContextAPIType | null>(
    null,
);
export const InteractionContextStatus = createContext<InteractionContextStatusType | null>(
    null,
);

export const InteractionsContextProvider = ({
    children,
    ...initProps
}: React.PropsWithChildren<InitProps>) => {
    const {
        hints,
        messages,
        activeStage,
        interrupt,
        sendMessage,
        agentState,
        isAgentBusy,
        isDisabled,
        awaitingResponse,
        addActionProcessor,
        removeActionProcessor,
    } = useInteraction(initProps);

    const APICtx = useMemo(
        () => ({
            interrupt,
            sendMessage,
            addActionProcessor,
            removeActionProcessor,
            activeStage,
        }),
        [
            activeStage,
            addActionProcessor,
            interrupt,
            removeActionProcessor,
            sendMessage,
        ],
    );

    const StatusCtx = useMemo(
        () => ({
            agentState,
            isDisabled,
            isAgentBusy,
            awaitingResponse,
        }),
        [agentState, awaitingResponse, isAgentBusy, isDisabled],
    );

    const DataCtx = useMemo(
        () => ({
            messages,
            hints,
        }),
        [hints, messages],
    );

    // Separating frequently updating data from API for better performance as suggested here
    // https://adevnadia.medium.com/react-re-renders-guide-preventing-unnecessary-re-renders-8a3d2acbdba3#:~:text=There%20is%20no%20way%20to,memo%20.

    return (
        <InteractionContextAPI.Provider value={APICtx}>
            <InteractionContextStatus.Provider value={StatusCtx}>
                <InteractionContextData.Provider value={DataCtx}>
                    {children}
                </InteractionContextData.Provider>
            </InteractionContextStatus.Provider>
        </InteractionContextAPI.Provider>
    );
};

export const useInteractionContext = <T,>(ctx: React.Context<T>) => {
    const state = useContext(ctx);
    if (state === null) {
        throw new Error('Missing InteractionContext.Provider in the tree');
    }
    return state;
};
