import * as React from 'react';
import { Fragment } from 'react';
import {
    Stack,
    HStack,
    Avatar,
    Button,
    Image,
    Wrap,
    WrapItem,
    Box,
} from '@chakra-ui/react';
import AvatarMessage from '../../atoms/AvatarMessage/AvatarMessage';
import UserMessage from '../../atoms/UserMessage/UserMessage';
import { getChatStyles } from '../../theme/consts';
import { useInteractionSettingsStore } from '../../stores';

interface Props {
    avatarImageSrc: string;
    isTextMode: boolean;
    onCitationClick: (citation: Citation) => void;
    messages: TextMessage[];
    innerContainerRef: React.RefObject<HTMLDivElement>;
}

type MessageProps = Omit<Props, 'messages' | 'innerContainerRef'> & {
    message: TextMessage;
};

const _Message: React.FC<MessageProps> = ({
    avatarImageSrc,
    isTextMode,
    onCitationClick,
    message,
}) => {
    const setAttachment = useInteractionSettingsStore(
        state => state.setAttachment,
    );
    const openModal = useInteractionSettingsStore(state => state.openModal);

    if (message.type === 'user') {
        return (
            <HStack justifyContent='flex-end'>
                <UserMessage text={message.text} isTextMode={isTextMode} />
            </HStack>
        );
    } else {
        return (
            <HStack alignItems='flex-start'>
                {isTextMode && <Avatar src={avatarImageSrc} boxSize='32px' />}
                <Box>
                    <AvatarMessage
                        text={message.text}
                        onCitationClick={onCitationClick}
                        isTextMode={isTextMode}
                    />
                    {message.attachments && (
                        <Wrap spacing={4} mb='10px!important'>
                            {message.attachments.map((attachment, i) => (
                                <WrapItem key={i}>
                                    <Button
                                        variant='unstyled'
                                        p='0px'
                                        w='125px'
                                        h='125px'
                                        onClick={() => {
                                            openModal();
                                            setAttachment({
                                                title: attachment.name,
                                                description:
                                                    attachment.description,
                                                url: attachment.url,
                                            });
                                        }}
                                    >
                                        <Image
                                            src={attachment.url}
                                            alt={attachment.name}
                                            objectFit='cover'
                                            w='125px'
                                            h='125px'
                                            borderRadius='10px'
                                        />
                                    </Button>
                                </WrapItem>
                            ))}
                        </Wrap>
                    )}
                </Box>
            </HStack>
        );
    }
};

// The message list changes often (especially with the typewriter effect running)
// so we use memoized components to skip re-rendering the already existing messages
const Message = React.memo(_Message, (prevProps, nextProps) => {
    return (
        prevProps.message.actionId === nextProps.message.actionId &&
        // Length eq is enough under the assumption that appending is the only operation on message.text
        prevProps.message.text.length === nextProps.message.text.length &&
        prevProps.isTextMode === nextProps.isTextMode &&
        prevProps.onCitationClick === nextProps.onCitationClick &&
        prevProps.avatarImageSrc === nextProps.avatarImageSrc
    );
});

const ChatHistory: React.FC<Props> = ({
    avatarImageSrc,
    isTextMode,
    onCitationClick,
    messages,
    innerContainerRef,
}) => {
    const styles = getChatStyles(isTextMode);

    return (
        <Fragment>
            <Stack
                {...styles.historyContainer}
                mx='auto'
                ref={innerContainerRef}
            >
                {messages.map((message, index) => (
                    <Message
                        key={index}
                        message={message}
                        isTextMode={isTextMode}
                        onCitationClick={onCitationClick}
                        avatarImageSrc={avatarImageSrc}
                    />
                ))}
            </Stack>
        </Fragment>
    );
};

export default ChatHistory;
