import * as React from 'react';
import { useCallback, useState } from 'react';
import { Grid, GridItem, HStack } from '@chakra-ui/react';
import Chat, { Props as ChatProps } from '../../organisms/Chat/Chat';
import ControlToggle from '../../molecules/ControlToggle/ControlToggle';
import { useInteractionSetting } from '../../stores';
import KeyControl from '../../atoms/KeyControl/KeyControl';
import {
    InteractionContextAPI,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';

const AvatarDesktopOverlayTemplate = (props: ChatProps) => {
    const {
        isAudioOn,
        toggleAudio,
        turnMicOn,
        turnMicOff,
        isSourcesDrawerOpen,
    } = useInteractionSetting([
        'isAudioOn',
        'toggleAudio',
        'turnMicOn',
        'turnMicOff',
        'isSourcesDrawerOpen',
    ]);
    const { interrupt } = useInteractionContext(InteractionContextAPI);
    const [showChatHistory, setShowChatHistory] = useState(true);

    const startRecognition = useCallback(() => {
        interrupt();
        turnMicOn();
    }, [interrupt, turnMicOn]);

    return (
        <Grid
            h='100%'
            w='100%'
            templateColumns={{
                base: '1fr 3fr',
                xl: isSourcesDrawerOpen ? '1fr 1fr 20%' : '3fr 2fr',
            }}
            position='fixed'
            bottom='0px'
            zIndex={10}
            p='125px 0px 20px 0px'
        >
            <GridItem /> {/* Empty slot */}
            <GridItem h='100%' overflowY='hidden'>
                <Chat {...props} showChatHistory={showChatHistory} />
                <HStack
                    w='75%'
                    h='10%'
                    justifyContent='flex-end'
                    m='0px auto 0px auto!important'
                >
                    <KeyControl
                        keyName='.'
                        onPush={startRecognition}
                        onRelease={turnMicOff}
                    />
                    <ControlToggle
                        ariaLabel='Audio toggle button'
                        icon={isAudioOn ? 'audioOn' : 'audioOff'}
                        buttonBackgroundColor={isAudioOn ? 'white' : 'black'}
                        tooltipLabel={
                            isAudioOn ? 'Turn audio off' : 'Turn audio on'
                        }
                        onClick={() => toggleAudio()}
                    />
                    <ControlToggle
                        ariaLabel='Chat history toggle button'
                        icon='chat'
                        buttonBackgroundColor={
                            showChatHistory ? 'white' : 'black'
                        }
                        tooltipLabel={
                            showChatHistory
                                ? 'Turn chat history off'
                                : 'Turn chat history on'
                        }
                        onClick={() => setShowChatHistory(value => !value)}
                    />
                </HStack>
            </GridItem>
        </Grid>
    );
};

export default AvatarDesktopOverlayTemplate;
