import * as React from 'react';
import { Fragment } from 'react';
import {
    Box,
    Button,
    Heading,
    HStack,
    IconButton,
    Image,
    Slide,
    Tag,
    Text,
    TextProps,
    useTheme,
    VStack,
} from '@chakra-ui/react';
import { Icon } from '../../atoms';
import CitationButton from '../../atoms/CitationButton/CitationButton';
import useStyling from '../../theme/useStyling';
import { useInteractionSettingsStore } from '../../stores';
import { scrollBarStyles } from '../../theme/consts';

interface Props {
    isSourcesDrawerOpen: boolean;
    onClose: () => void;
    currentSource: Citation | undefined;
}

interface TextWithHighlightProps extends TextProps {
    text: string;
    highlightSpan: [number, number];
}
const TextWithHighlight = ({
    text,
    highlightSpan,
    ...textProps
}: TextWithHighlightProps) => {
    const preHighlight = text.substring(0, highlightSpan[0]);
    const highlightedText = text.substring(highlightSpan[0], highlightSpan[1]);
    const postHighlight = text.substring(highlightSpan[1]);

    return (
        <Text {...textProps}>
            {preHighlight}
            {highlightedText && (
                <>
                    {' '}
                    <Tag
                        variant='subtle'
                        display='inline'
                        px='1'
                        fontSize='md'
                        borderRadius='0'
                    >
                        {highlightedText}
                    </Tag>{' '}
                </>
            )}
            {postHighlight}
        </Text>
    );
};

export default function SourcesSidePanel({
    isSourcesDrawerOpen,
    onClose,
    currentSource,
}: Props) {
    const theme = useTheme();
    const { sourcesSidePanelWidth } = useStyling();
    const setAttachment = useInteractionSettingsStore(
        state => state.setAttachment,
    );
    const openModal = useInteractionSettingsStore(state => state.openModal);

    return (
        <Slide
            direction='right'
            in={isSourcesDrawerOpen}
            style={{
                zIndex: 30,
                width: sourcesSidePanelWidth,
            }}
        >
            <Box h='100%' backgroundColor='dark.600' p='20px' color='white'>
                <HStack justifyContent='space-between' h='10%'>
                    <Heading color='white'>Sources</Heading>
                    <IconButton
                        aria-label='Close sources side panel'
                        backgroundColor='transparent'
                        _hover={{ backgroundColor: 'transparent' }}
                        size='sm'
                        onClick={onClose}
                    >
                        <Icon
                            iconChoice='x'
                            fontAwesomeProps={{
                                color: theme.colors.dark['400'],
                            }}
                        />
                    </IconButton>
                </HStack>
                {currentSource && (
                    <Fragment>
                        <HStack
                            h='90%'
                            overflowY='auto'
                            mb='20px'
                            pr='10px'
                            alignItems='flex-start'
                            spacing='5px'
                            sx={scrollBarStyles}
                        >
                            <Box>
                                <CitationButton citationId={currentSource.id} />
                            </Box>
                            <Box>
                                <Text m='0px' fontWeight={800}>
                                    {currentSource.title}
                                </Text>
                                <TextWithHighlight
                                    text={currentSource.content}
                                    highlightSpan={
                                        currentSource.highlighted_span || [0, 0]
                                    }
                                />
                                {currentSource.media && (
                                    <VStack
                                        my='10px'
                                        spacing={4}
                                        mb='10px!important'
                                    >
                                        {currentSource.media.map(mediaItem => (
                                            <Button
                                                key={mediaItem.url}
                                                variant='unstyled'
                                                p='0px'
                                                w='100%'
                                                h='150px'
                                                onClick={() => {
                                                    openModal();
                                                    setAttachment({
                                                        title:
                                                            mediaItem.caption,
                                                        url: mediaItem.url,
                                                        description:
                                                            mediaItem.description,
                                                    });
                                                }}
                                            >
                                                <Image
                                                    src={mediaItem.url}
                                                    alt={mediaItem.caption}
                                                    objectFit='cover'
                                                    w='100%'
                                                    h='150px'
                                                    borderRadius='10px'
                                                />
                                            </Button>
                                        ))}
                                    </VStack>
                                )}
                                <Text
                                    m='0px'
                                    textAlign='right'
                                    fontStyle='italic'
                                    fontSize='xs'
                                    color='dark.400'
                                >
                                    {currentSource.reference}
                                </Text>
                            </Box>
                        </HStack>
                    </Fragment>
                )}
            </Box>
        </Slide>
    );
}
